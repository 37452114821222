<template>
  <div>{{value}}</div>
</template>
<script>
export default {
  props: ["row", "header", "result"],
  computed: {
    value() {
      let status = this.row[this.header];
      let c_step_name = this.row["c_step_name"];
      let value;
      if (
        c_step_name == "Test completed with errors" ||
        c_step_name == "Test completed with warnings" ||
        c_step_name.trim() == "Test completed" ||
        status == 5
      ) {
        value = this.result.table.header[this.header].testStatuses[status];
      } else {
        value =
          this.row["n_current_step"] +
          "(" +
          this.getMappedName(this.row["c_step_name"]) +
          ")";
      }
      return value;
    },
  },
};

// , "dataFieldFunction" => function ($row) {
//         if ( $row['c_step_name'] == "Test completed with errors" || trim ($row['c_step_name']) == "Test completed" || $row['n_status'] == 5 ) {
//           return $row['n_status'];
//         } else {

//           return $row['n_current_step'] . "(" . getMappedName ($row['c_step_name']) . ")";
//         }
//       }
</script>